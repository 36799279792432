#gMap {

.angular-google-map{
    height: 100%;
    .angular-google-map-container { height: 100%; }
    }
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

   
}