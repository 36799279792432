#property {

    .header {
        height: 320px;
        min-height: 320px;
        max-height: 320px;
        background: url('/assets/images/backgrounds/march.jpg') no-repeat 0 45%;
        background-size: 100% auto;

        .profile-image {
            margin-right: 24px;
        }

        .name {
            font-size: 34px;
            color: #FFFFFF;
        }

        .actions {

            .md-button {
                text-transform: none;
                padding: 0 16px;
                height: 32px;
                line-height: 32px;
                margin: 0 0 0 8px;
            }
        }

        .user-info{
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.65))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}
    }

    .content {

        md-content {
            background-color: transparent;
        }
    }

    // Profile boxes
    .profile-box {
        margin-bottom: 16px;

        header {
            padding: 16px;

            .title {
                font-size: 17px;
            }

            .more {
                cursor: pointer;
            }
        }

        .content {
            padding: 16px;
            background-color: #FFF;
        }

        footer {
            padding: 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            background-color: rgba(0, 0, 0, 0.06);
        }

        &.info-box {

            .info-line {
                margin-bottom: 14px;

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    padding-bottom: 4px;
                }

                .info {

                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #property {

        .header {

            .profile-image {
                margin: 0 0 16px 0;
            }

            .name {
                margin-bottom: 32px;
            }
        }
    }

}